let environment = process.env.ENV
let env = {
  isDev: environment === "development",
  isStaging: environment === "staging",
  isProd: environment === "production",
}
const maxFileUploadSize = 1024 * 1024 * 15
const backend = "https://admin.emiratesmarsmission.ae"
let buildBackendHost = !env.isStaging ? "http://localhost:1337" : backend

module.exports = {
  backendHost: env.isDev ? "http://localhost:1337" : backend,
  maxFileUploadSize,
  backend,
  buildBackendHost,
  env,
}
